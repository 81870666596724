<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" :title="title" :showBack="true"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div v-if="type == 2">
              <label>Mobile number</label>
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <input type="tel" maxlength="10" v-model="$store.state.form.phone" placeholder="Mobile number" @blur="checkFormat('phone')" @input="errorFormat = false" />
                </div>
              </div>
              <p v-if="errorFormat" class="tip font-14 Helveticaneue" style="margin-top: 5px; color: red">Mobile format is incorrect</p>
            </div>
            <div v-else>
              <label>Email </label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="$store.state.form.email"
                  placeholder="Email Address"
                  @blur="checkFormat('email')"
                  @input="
                    $store.state.form.email = $store.state.form.email.trim().toLowerCase();
                    errorFormat = false;
                  "
                />
              </div>
              <p v-if="errorFormat" class="tip font-14 Helveticaneue" style="margin-top: 5px; color: red">Email format is incorrect</p>
            </div>
            <div class="pad-t-50">
              <ion-button expand="block" @click="confirmEvent" :disabled="isConfirm">Next</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "changeMobile",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonButton },
  data() {
    return {
      type: 1,
      title: "",
      errorFormat: false,
      isConfirm: true,
      canSendS: true,
      canSendE: true,
    };
  },
  methods: {
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      const objdata = this.getAuthObj();
      objdata.phone = this.$store.state.form.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$router.replace({
        path: "/verifyCode/3/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      const objdata = this.getAuthObj();
      objdata.email = this.$store.state.form.email.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$router.replace({
        path: "/verifyCode/3/1",
      });
    },
    confirmEvent() {
      this.$store.state.form.profileVerifyType = "editProfile";
      if (this.type == 2) {
        this.sendSmsEvent();
      } else {
        this.sendEmailEvent();
      }
      this.sendGAEvent("Click", this.title, "Send SMS");
    },
    checkFormat(type) {
      if (!this.$store.state[`${type}Patrn`].test(this.$store.state.form[type])) {
        this.errorFormat = true;
        this.isConfirm = true;
      } else {
        this.isConfirm = false;
      }
    },
  },
  ionViewWillEnter() {
    this.type = this.$route.params.type;
    if (this.type == 1) {
      this.title = "Set Email";
    } else if (this.type == 2) {
      this.title = "Set Mobile";
    }
    this.sendGAPageView(this.title);
    this.$store.state.exitApp = false;
  },
};
</script>
